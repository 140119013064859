<template>
  <div>
    <ds-header title="权益项目绑定"></ds-header>
    <div class="content-class">
      <div class="project-class">
        <a-radio-group v-model="currentProject.id" size="large">
          <a-radio-button :style="radioStyle" @click="projectClick(item.id)" v-for="(item, index) in projectList"
                          :value="item.id">
            {{ item.project_name }}
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="equity-class">
        <div class="current-project-class">
          权益项目：{{ currentProject.project_name }}
        </div>
        <!--模块-->
        <div class="equity-item-class" v-for="(item, index) in this.currentProjectEquity.moduleList" :key="index">
          <div>
            {{ item.moduleName }}：
          </div>
          <div class="content-item-class">
            <a-select
                mode="multiple"
                placeholder="请选择"
                style="width: 400px"
                :value="convertNowModuleId(item.moduleName)"
                @change="value => handleChange(value, item.moduleName)"
            >
              <a-select-option v-for="moduleItem in currentModuleAllEquity[item.moduleName]"
                               :key="moduleItem.benefitCrowdIdentityId">
                【{{ moduleItem.crowdIdentityName }}】  {{ moduleItem.benefitTitle }}
              </a-select-option>
            </a-select>
            <div>
              副标题：
              <a-input v-model.trim="item.moduleRemark" :maxLength="15" style="width: 344px" placeholder="请输入副标题，不超过15个字符"/>
            </div>
          </div>
        </div>
        <div class="binding-but-class">
          <a-button type="primary" @click="toBinding">绑定</a-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {queryEquityByProjectId, findEquityIdentifyByModuleName, projectBinding} from '../../../api/equityManagement'
import {mapState} from "vuex";

export default {
  name: "equityProjectBinding",
  computed: {
    ...mapState({
      projectList: (state) => {
        return state.common.projectList;
      },
    }),
  },
  watch: {
    projectList: {
      handler(val) {
        if(val && val.length) {
          this.currentProject = val[0]
          this.findEquityByProjectId(val[0].id);
        }
      }
    }
  },
  data() {
    return {
      // 当前模块下的所有权益
      currentModuleAllEquity: {},
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      projectData: [],
      // 当前项目权益
      currentProjectEquity: {},
      // 当前选中的项目
      currentProject: {},
    }
  },
  created() {
    // 获取所有项目
    // this.getAllProject();
  },
  methods: {
    // 获取所有项目
    getAllProject() {
      projectList().then(res => {
        if (res.code === '200') {
          this.projectData = res.data.items
          this.currentProject = this.projectData[0]
          this.findEquityByProjectId(this.currentProject.id);
        } else {
          this.$message.error("获取项目失败")
        }
      })
    },
    // 根据项目id查询权益
    findEquityByProjectId(id) {
      queryEquityByProjectId(id).then(res => {
        if (res.code === '200') {
          this.currentProjectEquity = res.data
          this.equityIdentityByModuleName()
        } else {
          this.$message.error('列表查询失败！')
        }
      })
    },
    // 根据模块名称查询可以关联的群益身份
    equityIdentityByModuleName() {
      if (this.currentProjectEquity.moduleList.length > 0) {
        // 当前项目下的模块
        const currentModuleList = this.currentProjectEquity.moduleList;
        // 根据模块名称循环查询可以关联的人群身份
        currentModuleList.forEach(item => {
          findEquityIdentifyByModuleName(item.moduleName).then(res => {
            this.currentModuleAllEquity[item.moduleName] = res.data
          })
        })
      } else {
        this.$message.warning("当前项目没有权益")
      }
    },
    // 点击项目
    projectClick(id) {
      this.currentProject = this.projectData.find((item) => item.id === id
      )
      this.findEquityByProjectId(id)
    },
    handleChange(ids, moduleName) {
      const newModule = []
      if (ids) {
        ids.forEach(item => {
          let newEquity = this.currentModuleAllEquity[moduleName].find(arr => arr.benefitCrowdIdentityId === item)
          if (newEquity) {
            newModule.push(newEquity)
          }
        })
        let moduleIndex = -1
        this.currentProjectEquity.moduleList.forEach((items, index) => {
          if (items.moduleName === moduleName) {
            moduleIndex = index
          }
        })
        this.currentProjectEquity.moduleList[moduleIndex].benefitList = newModule
      }
    },
    // 去绑定
    toBinding() {
      projectBinding(this.currentProjectEquity).then(res => {
        if (res.code === '200') {
          this.$message.success('操作成功！')
          this.findEquityByProjectId(this.currentProject.id);
        } else {
          this.$message.error("操作失败！")
        }
      })
    },

    // 组装当前模块的权益id
    convertNowModuleId(data) {
      const moduleIds = []
      const newModule = this.currentProjectEquity.moduleList.filter(item => {
        return item.moduleName === data
      })
      if (newModule.length > 0 && newModule[0].benefitList.length > 0) {
        newModule[0].benefitList.forEach(arr => {
          moduleIds.push(arr.benefitCrowdIdentityId)
        })
      }
      return moduleIds;
    }
  }
}
</script>

<style lang="scss" scoped>
.content-class {
  display: flex;
  flex-direction: row;

  .project-class {
    width: 500px;

    .project-item-class {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000000;
      width: 260px;
      height: 50px;
      margin-top: 1px;
    }

    .active {
      color: red;
    }
  }

  .equity-class {
    width: 100%;
    margin-left: 100px;
    margin-top: 50px;

    .current-project-class {
      font-weight: 500;
    }

    .equity-item-class {
      display: flex;
      line-height: 40px;
      margin-top: 10px;
      flex-direction: column;

      .content-item-class {
        margin-left: 20px;
      }
    }

    .binding-but-class button {
      margin-top: 30px;
      margin-left: 200px;
    }
  }
}

</style>
